(function($) {

    // on Scroll logo resize
    $(window).on("scroll", function() {
        if ($(window).scrollTop() > 50) {
            $(".navbar").addClass("fixed-top-zero");
            $(".navbar-brand-img").addClass("logo-resize");
        } else {
            //remove the background property so it comes transparent again (defined in your css)
            $(".navbar").removeClass("fixed-top-zero");
            $(".navbar-brand-img").removeClass("logo-resize");
        }
    });


})(jQuery);

