document.addEventListener( 'DOMContentLoaded', function() {
  if( jQuery('#testimonial').length < 1 )
  return;
  var splide = new Splide( '#testimonial' , {
        type   : 'loop',
        perPage: 3,
        breakpoints: {
            1200: {
                perPage: 3,
               
              },
            1024: {
              perPage: 2,
             
            },
            767: {
              perPage: 2,
          
            },
            640: {
              perPage: 1,
        
            },
          },
        perMove: 1,
        gap: '2em',
        pagination:false,

      } );
      splide.mount();
} );

document.addEventListener( 'DOMContentLoaded', function() {
  if( jQuery('#gameprovider').length < 1 )
  return;
  var splide = new Splide( '#gameprovider' , {
        type   : 'loop',
        perPage: 4,
        autoplay: true,
        infinite: true,
        breakpoints: {
            1200: {
                perPage: 4,
               
              },
            1024: {
              perPage: 3,
             
            },
            767: {
              perPage: 2,
          
            },
            640: {
              perPage: 1,
        
            },
          },
        perMove: 1,
        gap: '1em',
        pagination:false,

      } );
      splide.mount();
} );